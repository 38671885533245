<template>
  <div class="BlogID">
    
    <v-container 
    justify="center"  
    fill-height> 
      <v-row justify='space-between' class="pa-3 pb-6">
        <v-btn @click="$router.push({name:'BlogEntry',params:{blogID:previousArticleID}})" 
        outlined
        :small="$vuetify.breakpoint.smAndDown" 
        :disabled="!previousArticleID"
        >
            <v-icon dark > mdi-arrow-left
            </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="$router.push({name:'Blog'})" 
        :small="$vuetify.breakpoint.smAndDown"
        color="lightgrey"
        elevation="0"> 
          <v-icon   dark > mdi-view-dashboard-variant-outline
          </v-icon>
        </v-btn>  
        <v-spacer></v-spacer>
        <v-btn @click="$router.push({name:'BlogEntry',params:{blogID:nextArticleID}})" 
          outlined
          :small="$vuetify.breakpoint.smAndDown"
          :disabled="!nextArticleID"
          > 
            <v-icon dark > mdi-arrow-right
            </v-icon>
        </v-btn>      
      </v-row>

      <!-- Title  -->
      <v-row no-gutters>
        <v-col 
          cols="8"
          no-gutters>
          <h2 align="left">
            {{article.title}} </h2>
        </v-col>
        <v-col
            cols="4">  
          <p 
            class = " text-right font-weight-thin text-body-1"  > 
            {{article.date}} 
            <p/>
          
        </v-col>
      
      </v-row>
      <v-row
         v-if="article.text_en||article.text_de">
        <v-icon class='px-3' color='primary'>mdi-web</v-icon> 
        <v-btn-toggle
        color='primary'
        v-model="lang_de">
          <v-btn small>
            EN
          </v-btn>      
          <v-btn small>
            DE
          </v-btn>
        </v-btn-toggle>
        <!-- <v-switch
          v-model="lang_de"
          append-icon="mdi-web" 
          :label="language"> 
      </v-switch> -->
  
      </v-row>
      
      <v-divider class="ma-4"></v-divider>
      
      <!-- Text  -->
      <v-container class="grey lighten-5 "
          width="98vw"
          v-if="article.text_en||article.text_de">
        <p 
        v-if="lang_de"
        class="linebreak text-justify" >
          {{article.text_de}} 
        </p>   
        <p 
          v-if="!lang_de"
          class="linebreak text-justify" >
          {{article.text_en}}     
        </p>
        
      <p
        style="text-align:right;">
        <br><br>
        Publish date: {{article.publishDate.substring(0,10) }} <br>
        Author: {{article.author}}

      </p>
      </v-container>
        
      <!-- Gallery  -->
      <v-row align="center" justify="center" class="pb-5">
        <v-col
          v-for="(p,index) in pictures"
          :key="index"
          class="d-flex child-flex"
          cols="12"
          :sm="colnumber"
          >
          <v-img
            :lazy-src="p.thumbUrl"
            height="250"
            width="300"
            :src="p.url"
            @click="OverlayIndex=index+1"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col> 
      </v-row> 

      <!-- Gallery overlay  -->
      <v-overlay :value="OverlayIndex">
        <v-card       
            v-if="OverlayIndex"      
            width="98vw"
            height="98vh"
            class="pa-5"
            outlined
            >

          <v-img
            :lazy-src="OverlayImage.thumbUrl"
            :src="OverlayImage.url"
            height="100%"
            width="100%"
            class="my-auto"
            contain 
            >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
              <br>
              <v-row
                justify="center">
              <v-card
                class=" black--text  grey lighten-5 title ma-1"
                style="opacity: 0.65"
                width="30%"
              >
              <!-- <p class = "font-weight-light" > 
                {{OverlayImage.pictext}}</p> -->
                
              </v-card>
            </v-row>
          
<!-- overlay buttons -->
  
          <v-btn
            class="BackButton"
            color="primary"
            fab

            @click="PreviousImg"
            absolute
            :small="$vuetify.breakpoint.smAndDown"
            >
            <v-icon dark > mdi-arrow-left
            </v-icon>
          </v-btn>

          <v-btn
            color="grey" 
            fab
            @click="OverlayIndex = null"
            class="CloseButton ma-2"
            absolute
            :small="$vuetify.breakpoint.smAndDown"
            >
              <v-icon dark > mdi-close
              </v-icon>
          </v-btn>
          

          <v-btn
            class="ForwardButton"
            color="primary"
            @click="NextImg"
            fab
            absolute
            :small="$vuetify.breakpoint.smAndDown"

            >
            <v-icon dark > mdi-arrow-right
            </v-icon>
          </v-btn>

          </v-img>




        </v-card>

      </v-overlay>
      <br>

      <!-- Navigation buttons -->
      <!-- :class="$vuetify.breakpoint.smAndUp ? 'PageNavigation' : 'PageNavigation2'" --> 

      <!-- <v-row  
        justify='space-between' class="pa-3">
          <v-btn @click="$router.push({name:'BlogEntry',params:{blogID:previousArticleID}})" 
          outlined
          :small="$vuetify.breakpoint.smAndDown"
          :disabled="!previousArticleID"
          >
              <v-icon dark left> mdi-arrow-left
              </v-icon>
              Previous 
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="$router.push({name:'BlogEntry',params:{blogID:nextArticleID}})" 
          outlined
          :small="$vuetify.breakpoint.smAndDown"
          :disabled="!nextArticleID"
          > 
              Next
              <v-icon dark left class='pl-5' > mdi-arrow-right
              </v-icon>
          </v-btn>  
      </v-row>
      <v-row
        justify = 'space-around' class="pb-6">
          <v-btn @click="$router.push({name:'Blog'})" 
          :small="$vuetify.breakpoint.smAndDown"
          color="grey"> 
            <v-icon  left dark > mdi-arrow-left
            </v-icon>
          </v-btn>
      </v-row> -->
      
      <v-row justify='space-between' class="pa-3 pb-6">
        <v-btn @click="$router.push({name:'BlogEntry',params:{blogID:previousArticleID}})" 
        outlined
        :small="$vuetify.breakpoint.smAndDown" 
        :disabled="!previousArticleID"
        >
            <v-icon  left > mdi-arrow-left
            </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="$router.push({name:'Blog'})" 
        :small="$vuetify.breakpoint.smAndDown"
        color="lightgrey"
        elevation="0"> 
          <v-icon  dark > mdi-view-dashboard-variant-outline
          </v-icon>
        </v-btn>  
        <v-spacer></v-spacer>
        <v-btn @click="$router.push({name:'BlogEntry',params:{blogID:nextArticleID}})" 
          outlined
          :small="$vuetify.breakpoint.smAndDown"
          :disabled="!nextArticleID"
          > 
            <v-icon dark > mdi-arrow-right
            </v-icon>
        </v-btn>      
      </v-row>
    </v-container>


  </div>   

</template>



<script>
import { mapGetters } from 'vuex'
  // import { mapState } from 'vuex'
  export default {
    name: 'BlogID',
    data()  {
      return {
        lang_de: true,
        OverlayIndex: null
      }
    },
    computed: {
      // ...mapState(['pictures']),
      ...mapGetters(['nextArticleID']),
      article() {
        return this.$store.getters.articleByID(this.blogID)
      },
      nextArticleID(){
        return this.$store.getters.nextArticleID(this.blogID)
      },
      previousArticleID(){
        return this.$store.getters.previousArticleID(this.blogID)
      },
      pictures() {
        return this.$store.getters.articleLinkedPictures(this.blogID)
      },
      OverlayImage() {
        console.log(this.OverlayIndex-1)
        let newImag = this.pictures[this.OverlayIndex-1]
        return newImag
      },
      colnumber() {
        return 6
      },
      language() {
        if (this.lang_de) {
          return 'deutsch'
        } else {
          return 'english'
        }
      },
    }, 

    methods: {
      PreviousImg() {
        if (this.OverlayIndex === 1) {
          this.OverlayIndex = this.pictures.length;
        } else {
          this.OverlayIndex--;
        }
      },
      NextImg() {
        if (this.OverlayIndex >= this.pictures.length) {
          this.OverlayIndex = 1;
        } else {
          this.OverlayIndex++;
        }
      }
    }, 

    props: {
      blogID: {
        required: true
      },
    },

    components: {
    //  Articles: () => import('@/components/home/Articles'),
    //   Banner: () => import('@/components/home/Banner'),
    }, 
          
}
</script>

<style>
.BackButton {
  position: absolute;
  top: 50%;
  left: +10px;
  z-index: 10;
}
.CloseButton {
  position: absolute;
  top: 0%;
  left: +10px;
  z-index: 5;
}
.ForwardButton {
  position: absolute;
  right: +10px;
  top: 50%;
  z-index: 10;
  @media $display-breakpoints.xs-only
    color: red!important

}
.PageNavigationAbsolute {
    position: absolute;
    bottom: 10%;
}
.PageNavigation {
    bottom: 10%;
}
.linebreak {
  white-space: pre-line
}

</style>



      <!-- <v-carousel>
        <v-carousel-item
          v-for="(p,i) in pictures"
          :key="i"
          :lazy-src="p.thumbUrl"
          :src="p.url"
          reverse-transition="fade-transition"
          transition="fade-transition"
        ></v-carousel-item>
      </v-carousel>   -->
        <!-- Back button. -->